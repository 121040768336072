import { DeviceConfigurationDTO } from 'api/api_code'
import FieldSettings from 'components/field-configuration/field-settings/FieldSettings'
import SmartScoreboardConfig from 'components/field-configuration/smart-scoreboard-config/SmartScoreboardConfig'
import SportsConfig from 'components/field-configuration/sports-config/SportsConfig'
import { Step } from 'types/global'

export const enum FIELD_CONFIGURATION_STEPS_ENUM {
  SETTINGS = 1,
  SPORTS = 2,
  SCOREBOARD = 3,
}

export const fieldConfigurationSteps: Step[] = [
  {
    index: FIELD_CONFIGURATION_STEPS_ENUM.SETTINGS,
    component: <FieldSettings />,
    isCompleted: (data: DeviceConfigurationDTO) => {
      return Boolean(
        data?.details?.name && data?.details?.name.length <= 50 && data.details.timezone
      )
    },
  },
  {
    index: FIELD_CONFIGURATION_STEPS_ENUM.SPORTS,
    component: <SportsConfig />,
    isCompleted: (data: DeviceConfigurationDTO) => {
      return data?.configured_sports.length > 0
    },
  },
  {
    index: FIELD_CONFIGURATION_STEPS_ENUM.SCOREBOARD,
    component: <SmartScoreboardConfig />,
    isCompleted: (data: DeviceConfigurationDTO) => {
      return (
        data?.scoreboard_configuration === null ||
        data?.scoreboard_configuration?.input_plugin !== 'ocr' ||
        (data?.scoreboard_configuration?.input_plugin === 'ocr' &&
          data?.scoreboard_configuration?.ocr_files &&
          data?.scoreboard_configuration?.ocr_files.length > 0)
      )
    },
  },
]

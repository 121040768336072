import { useGlobal } from './useGlobal'
import { requestHandler as AxiosRequestHandler } from 'api/axios/helpers'
import { AxiosError, HttpStatusCode } from 'axios'
import { useRouter } from 'next/router'
import { Button, Typography } from 'antd'
import { DeviceConfigurationEndpoints } from 'api/axios/axiosAPIGroups'
import { ExceptionDetail, SessionDTO } from 'api/api_code'
import { useLoading } from './useLoading'
import { AxiosManager } from 'api/axios/axios'
import { setToSessionStorage } from 'helpers/storageUtils'
import { X_WIZARD_SESSION_TOKEN } from 'api/axios/constants'
import { useCallback } from 'react'
import { IconType } from 'components/UI/Modals/NotificationModal'
import useTranslation from 'next-translate/useTranslation'
import { getSportName } from 'helpers/utils'
import Trans from 'next-translate/Trans'
import Link from 'antd/lib/typography/Link'

export function useHTTPRequestHandler() {
  const { setOpenNotification } = useGlobal()
  const router = useRouter()
  const device_uuid = router.query['device_uuid'] ?? 'noToken'
  const { setLoading } = useLoading()
  const { t } = useTranslation('common')

  const forceStartSession = ({
    device_uuid,
    callback,
    reload = true,
  }: {
    device_uuid: string
    callback?: CallableFunction
    reload?: boolean
  }) => {
    requestHandler({
      requestPromise:
        DeviceConfigurationEndpoints.startWizardSessionDevicesDeviceUuidConfigurationSessionPost(
          device_uuid,
          true
        ),
      onOkCallback: (session: SessionDTO) => {
        setToSessionStorage('session_token', session['token'])
        AxiosManager.getInstance().addCommonHeader(X_WIZARD_SESSION_TOKEN, session['token'])
        if (callback) {
          return callback()
        }
        reload && router.reload()
      },
    })
  }

  const cancelForceSession = useCallback(() => {
    setOpenNotification({ open: false })
    if (router.pathname !== '/camera/[device_uuid]/details') {
      router.push({
        pathname: '/camera/[device_uuid]/details',
        query: { device_uuid },
      })
    }
  }, [])

  const checkErrors = (error: AxiosError, onKoCallback: CallableFunction) => {
    const internalCode = (error.response.data as ExceptionDetail).code
    const internalMsg = (error.response.data as ExceptionDetail).message
    // Session errors
    const { t } = useTranslation('common')
    let title = '',
      body = <></>,
      onOk = () => {
        setOpenNotification({ open: false })
        router.push('/')
      }
    if (
      error.response.status === HttpStatusCode.BadRequest &&
      (internalCode === 114 || internalCode === 128 || internalCode === 129)
    ) {
      title = t('sessionModal.title')
      body = (
        <div>
          <div>{internalMsg}</div>
          <div>{t('sessionModal.request')}</div>
        </div>
      )

      return {
        title,
        body,
        onOk: () => null,
        onCancel: cancelForceSession,
        footerBtns: [
          <Button
            key="cancel-button"
            aria-label="cancel close session"
            onClick={cancelForceSession}
          >
            {t('sessionModal.cancel')}
          </Button>,
          <Button
            key="confirm-button"
            aria-label="confirm close session"
            type="primary"
            onClick={() => {
              forceStartSession({ device_uuid: device_uuid as string, callback: onKoCallback })
              setOpenNotification({ open: false })
            }}
          >
            {t('sessionModal.confirm')}
          </Button>,
        ],
      }
    }
    if (internalCode === 132) {
      const splittedUrl = error.response.config.url.split('/')
      const sport_uuid = splittedUrl[splittedUrl.length - 2]
      title = t('modal.deleteSportWithPreset.title')
      body = (
        <Typography.Paragraph>
          {t('modal.deleteSportWithPreset.description', { sport: getSportName(sport_uuid) })}
        </Typography.Paragraph>
      )
      onOk = () => {
        setOpenNotification({ open: false })
      }
      const footerBtns = [
        <Button key="modal-footer-btn" onClick={onOk} type="ghost" style={{ width: 'max-content' }}>
          {t('ok')}
        </Button>,
      ]
      const titleIconType = IconType.KO
      return {
        titleIconType,
        title,
        body,
        onOk,
        onCancel: onOk,
        footerBtns,
      }
    }
    if (internalCode === 135) {
      title = t('recordingModal.title')
      body = <Typography.Paragraph>{t('recordingModal.explanation')}</Typography.Paragraph>
      onOk = () => {
        setOpenNotification({ open: false })
        router.push('/')
      }
      const footerBtns = [
        <Button key="modal-footer-btn" onClick={onOk} type="ghost" style={{ width: 'max-content' }}>
          {t('ok')}
        </Button>,
      ]
      const titleIconType = IconType.RECORDING
      return {
        titleIconType,
        title,
        body,
        onOk,
        onCancel: onOk,
        footerBtns,
      }
    }
    return {
      onOk,
      closable: false,
      title: t('modal.generic.title'),
      body: (
        <div>
          <Trans
            i18nKey="common:modal.generic.description"
            components={[
              <Link key="mail-support" href="mailto:support@automatic.tv">
                {t('supportEmail')}
              </Link>,
            ]}
          />
        </div>
      ),
    }
  }

  const defaultErrorCallback = (error, onKoCallback: CallableFunction) => {
    /**
     * Code 130: doesn't have any session active
     * do not show any notification, redirect to camera list page
     */
    if (
      error instanceof AxiosError &&
      (error.response.data as ExceptionDetail).code === 130 &&
      error.response.status === 400
    ) {
      if (router.pathname !== '/') forceStartSession({ device_uuid: device_uuid as string })
      return false
    }
    /**
     * Code 401: user not logged
     * do not show any notification, redirect to sso login
     */
    if (error instanceof AxiosError && error.response.status === 401) {
      return false
    }
    let notificationProps = {
      title: t('modal.generic.title'),
      body: (
        <div>
          <Trans
            i18nKey="common:modal.generic.description"
            components={[
              <Link key="mail-support" href="mailto:support@automatic.tv">
                {t('supportEmail')}
              </Link>,
            ]}
          />
        </div>
      ),
    }
    if (error instanceof AxiosError) {
      notificationProps = checkErrors(error, onKoCallback)
    }

    setOpenNotification({
      open: true,
      ...notificationProps,
    })
  }
  const handleKoCallback = (
    error,
    onKo: { onKoCallback: CallableFunction; overrideCallback?: boolean }
  ) => {
    setLoading(false)

    if (onKo?.overrideCallback) {
      onKo.onKoCallback(error)
    } else {
      defaultErrorCallback(error, onKo?.onKoCallback)
    }
  }

  const requestHandler = ({
    requestPromise,
    onOkCallback,
    onKo,
    autoLoading = true,
  }: {
    requestPromise: Promise<any>
    onOkCallback?: CallableFunction
    onKo?: { onKoCallback: CallableFunction; overrideCallback?: boolean }
    autoLoading?: boolean
  }) => {
    if (autoLoading) {
      setLoading(true)
    }
    AxiosRequestHandler.call(
      this,
      requestPromise,
      (requestData, pendingRequests) => {
        if (pendingRequests === 0 && autoLoading) {
          setLoading(false)
        }
        onOkCallback && onOkCallback(requestData)
      },
      (error) => handleKoCallback(error, onKo)
    )
  }

  return {
    requestHandler,
    cancelForceSession,
    forceStartSession,
  }
}

import BASKETBALL from 'assets/illustrations/Basketball.svg'
import ACE from 'assets/cameras/Ace.svg'
import ONE from 'assets/cameras/One.svg'
import CUSTOMHTMLSCOREBUG from 'assets/illustrations/CustomHTML.svg'
import FOOTBALL from 'assets/illustrations/Football.svg'
import FOOTSAL from 'assets/illustrations/Footsal.svg'
import HANDBALL from 'assets/illustrations/Handball.svg'
import HOCKEY from 'assets/illustrations/Hockey.svg'
import RUGBY from 'assets/illustrations/Rugby.svg'
import VOLEYBALL from 'assets/illustrations/Voleyball.svg'
import GENERIC from 'assets/illustrations/Generic.svg'
import ACE_WITH_BG from 'assets/cameras/AceWithBackground.svg'
import ONE_WITH_BG from 'assets/cameras/OneWithBackground.svg'
import PERMISSIONERROR from 'assets/illustrations/PermissionError.svg'
import PIP from 'assets/illustrations/PIP.svg'
import SMARTSCOREBOARD from 'assets/illustrations/SmartScoreBoard.svg'
import SHIELD from 'assets/illustrations/Shield.svg'
import STAR from 'assets/illustrations/Star 1.svg'
import FIELD from 'assets/illustrations/Field.svg'
import MANUALSCOREBUG from 'assets/illustrations/ManualScorebug.svg'

export {
  BASKETBALL,
  ACE,
  ONE,
  CUSTOMHTMLSCOREBUG,
  FOOTBALL,
  FOOTSAL,
  HANDBALL,
  HOCKEY,
  RUGBY,
  VOLEYBALL,
  GENERIC,
  ACE_WITH_BG,
  ONE_WITH_BG,
  PERMISSIONERROR,
  PIP,
  SMARTSCOREBOARD,
  SHIELD,
  STAR,
  FIELD,
  MANUALSCOREBUG,
}
